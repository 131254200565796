import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AccountTierResponse } from '@app/core/models/client/account/account-tier.model';
import { environment } from '@core/environments/environment';

import {
  AccountInitiator,
  AccountSignatory,
  AccountsAttachedToUser,
  AccountsAttachedToUserResponse,
  AssignSignatory,
  DeactivateSignatory,
  ProfilesAttachedToAccount,
  UpdateAccountInfo,
} from '@core/models/client/account';
import { Observable, finalize, tap } from 'rxjs';
import { LoadingService } from '../loading.service';
import { AccountDetailsResponse } from '@app/core/models/client/account/account-details.model';
import { AccountRMResponse } from '@app/core/models/client/account/account-rm.models';
import { EnumsResponse } from '@app/core/models/utils/enums.model';
import { LoginResponse } from '@app/core/models/client/auth';


@Injectable({
  providedIn: 'root',
})
export class AccountService {
  http = inject(HttpClient);
  loadingService = inject(LoadingService);

  BASE_URL = environment.CLIENT_BASE_URL + '/v1/account';
  USER_BASE_URL = environment.CLIENT_BASE_URL + '/v1/users';

  constructor() {}

  assignSignatory(signatoryInfo: AssignSignatory) {
    return this.http.post(this.BASE_URL, signatoryInfo);
  }

  updateAccountInfo(accountInfo: UpdateAccountInfo) {
    return this.http.patch(this.BASE_URL, accountInfo);
  }

  accountInitiator(accountInitiator: AccountInitiator) {
    return this.http.post(this.BASE_URL, accountInitiator);
  }

  getProfilesAttachedToAccount() {
    const account_id = "hello"
    return this.http.post(this.BASE_URL, account_id);
  }

  getAccountsAttachedToUsers(profileInfo: AccountsAttachedToUser): Observable<AccountsAttachedToUserResponse> {
    return this.http.post<AccountsAttachedToUserResponse>(`${this.BASE_URL}/get-profile-accounts`, profileInfo);
  }

  deactivateSignatory(signatoryInfo: DeactivateSignatory) {
    return this.http.post(this.BASE_URL, signatoryInfo);
  }

  getAccountSignatories(accountInfo: AccountSignatory) {
    return this.http.post(this.BASE_URL, accountInfo);
  }
  getAccountDetails(account_id: string) {
    return this.http.get<AccountDetailsResponse>(`${this.BASE_URL}/get-account-details?account_id=${account_id}`)

  }
   getAllEnums(): Observable<EnumsResponse> {
    return this.http.get<EnumsResponse>(`${this.USER_BASE_URL}/get-enums`);
  }

 getIndividualAccountKyc(account_id: string){
    return this.http.get(`${this.BASE_URL}/get-individual-account-kyc?account_id=${account_id}`)
  }
  getCoperateAccountKyc(account_id: string){
    return this.http.get(`${this.BASE_URL}/get-corporate-account-kyc?account_id=${account_id}`)
  }
  getAccountKycTier(accountId: string): Observable<AccountTierResponse> {
    const uniqueStateUrl = 'check-kyc-tier-level';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .get<AccountTierResponse>(
        `${this.BASE_URL}/check-kyc-tier-level?account_id=${accountId}`
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

   getRefreshToken(): Observable<LoginResponse> {
      return this.http
        .get<LoginResponse>(`${this.USER_BASE_URL}/refresh-token`)
        .pipe(
          tap((response) => {
            if (response.token) {
                sessionStorage.removeItem("accessToken");
                sessionStorage.setItem("accessToken", response.token);
            }
          }),
        );
    }

    getAccountRelationshipManager(accountId: string): Observable<AccountRMResponse> {
      const uniqueStateUrl = 'get-account-relationship-manager';
      this.loadingService.setLoading(uniqueStateUrl, true);

      return this.http
        .get<AccountRMResponse>(
          `${this.BASE_URL}/get-account-relation-manager?account_id=${accountId}`
        )
        .pipe(
          finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
        );
    }
}


